<template>
    <app-wrapper>
        <template v-slot:child>
            <!--Entity and btn import, add new entity -->
            <EntityBulkUploadModal :closeModal="() => toggleEntityBulkImportModal(false)" />
            <template v-if="!entities">
                <Spinner />
            </template>

            <div class="w-90 center">
                <div class="flex flex-wrap justify-between items-center pv3">
                    <div>
                        <p class="pb2 f3 b">
                            {{ entyValue }}
                            <strong
                                ><font-awesome-icon
                                    icon="fa-solid fa-question-circle fa-2xs"
                                    class="text-muted clickModalIcon"
                                    @click="clickModalEntities = true"
                                    style="cursor: pointer"
                            /></strong>
                        </p>
                        <div class="dashboard-inquiry-modal" v-if="clickModalEntities">
                            This shows an overview of all your customers, including suppliers, debtors and investors. Click
                            <a href="#" target="_blank" style="color: blue">here</a> to learn more.
                        </div>
                    </div>
                    <div class="scrollmenu flex">
                        <button v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(30)" class="mr2">
                            <router-link to="#entity-form" @click="() => toggleCreateEntity(true)"
                                >+ Add new customer</router-link
                            >
                        </button>
                        
                        <button
                            style="background-color: white; border: 1px solid #132C8C; color: #132C8C;"
                            class="dropdown-togglex "
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            More <font-awesome-icon icon="fa-solid fa-caret-down" class="fa-2x" />
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" style=" z-index: 2000">
                            <li v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(66)" style="cursor: pointer" @click="toggleEntityBulkImportModal(true)">Import</li>
                            <li v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(66)" style="cursor: pointer" @click="exportEntities">Export</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!--Entity and btn import, add new entity -->

            <div class="w-90 center mb3">
                <div
                    class="pv3 flex flex-wrap justify-between items-center"
                    style="border-top: 1px solid #e2e8ee; border-bottom: 1px solid #e2e8ee"
                >
                    <div class="flex flex-wrap items-start w-60-l w-100 pt0-l pt3">
                        <div class="entity-filter-dropdown" :class="$store.state.Alert?.showMobileMenu && 'unZIndex'">
                            <button
                                class="flex justify-center items-center filter card-box entity-filter-btn mr3"
                                @click.prevent="toggleEntityRoleFilter"
                                style="padding: 13px 0"
                            >
                                <img :src="require('@/assets/images/filter_list.svg')" style="width: 20px" alt="filter" />
                                <span class="pl2 w-50 tl black">Filter</span>
                            </button>
                            <div class="options" v-show="state.isEntityRoleOpen">
                                <div
                                    class="option"
                                    v-for="(option, index) in entityRoles"
                                    @click="filterEntity(option)"
                                    :key="index"
                                >
                                    {{ option }}
                                </div>
                            </div>
                        </div>

                        <div class="filter card-box1x ml3" style="padding: 8px 0">
                            <div class="tc flex items-center ph3">
                                <img :src="require('@/assets/images/Reload.svg')" alt="" />
                            </div>
                        </div>
                    </div>

                    <div class="w-40-l w-100">
                        <SearchInput placeholder="Search customers..." v-model="state.searchVal" @update:modelValue="search" />
                    </div>
                </div>
            </div>

            <!--      Create Entity Modal / Form-->
            <CreateEntity :closeModal="() => toggleCreateEntity()" />

            <div class="scrollmenu w-90 center">
                <table v-if="entities?.data.length" class="w-100 tl mt3" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="pb3 pr3 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>Customer ID</span>
                                </span>
                            </th>
                            <th class="pb3 pr3 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>Customer Name</span>
                                    <span>
                                        <span class="header-filter-sort-icon">
                                            <span
                                                class="expand sort1"
                                                v-if="state.isOpen && getTheValue == 'firstName'"
                                                @click="sortTransactionsTable('asc', 'firstName')"
                                            >
                                                <img
                                                    :src="require('@/assets/images/sort-up.png')"
                                                    alt=""
                                                    class="directional-up"
                                                    style="height: 8px; width: 7px"
                                                />
                                            </span>
                                            <span v-else class="expand sort1" @click="sortTransactionsTable('desc', 'firstName')">
                                                <img
                                                    :src="require('@/assets/images/caret-down.png')"
                                                    alt=""
                                                    class="directional-up"
                                                    style="height: 8px; width: 7px"
                                                />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </th>
                            <th class="pb3 pr3 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>Email</span>
                                    <span>
                                        <span class="header-filter-sort-icon">
                                            <span
                                                class="expand sort1"
                                                v-if="state.isOpen && getTheValue == 'email'"
                                                @click="sortTransactionsTable('asc', 'email')"
                                            >
                                                <img
                                                    :src="require('@/assets/images/sort-up.png')"
                                                    alt=""
                                                    class="directional-up"
                                                    style="height: 8px; width: 7px"
                                                />
                                            </span>
                                            <span v-else class="expand sort1" @click="sortTransactionsTable('desc', 'email')">
                                                <img
                                                    :src="require('@/assets/images/caret-down.png')"
                                                    alt=""
                                                    class="directional-up"
                                                    style="height: 8px; width: 7px"
                                                />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </th>
                            <th class="pb3 box-border-bottom tl pr2" >
                                <span class="flex items-center">
                                    <span>Phone No</span>
                                    <span>
                                        <span class="header-filter-sort-icon">
                                            <span
                                                class="expand sort1"
                                                v-if="state.isOpen && getTheValue == 'phone'"
                                                @click="sortTransactionsTable('asc', 'phone')"
                                            >
                                                <img
                                                    :src="require('@/assets/images/sort-up.png')"
                                                    alt=""
                                                    class="directional-up"
                                                    style="height: 8px; width: 7px"
                                                />
                                            </span>
                                            <span v-else class="expand sort1" @click="sortTransactionsTable('desc', 'phone')">
                                                <img
                                                    :src="require('@/assets/images/caret-down.png')"
                                                    alt=""
                                                    class="directional-up"
                                                    style="height: 8px; width: 7px"
                                                />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </th>
                            <th class="pb3 box-border-bottom tl">Role</th>
                            <th class="pb3 box-border-bottom tl" style="padding-right: 20px">
                                Sales Amount
                            </th>
                            <th class="pb3 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>Last Transaction</span>
                                    <span>
                                        <span class="header-filter-sort-icon">
                                            <span
                                                class="expand sort1"
                                                v-if="state.isOpen && getTheValue == 'lastTransactionDate'"
                                                @click="sortTransactionsTable('asc', 'lastTransactionDate')"
                                            >
                                                <img
                                                    :src="require('@/assets/images/sort-up.png')"
                                                    alt=""
                                                    class="directional-up"
                                                    style="height: 8px; width: 7px"
                                                />
                                            </span>
                                            <span
                                                v-else
                                                class="expand sort1"
                                                @click="sortTransactionsTable('desc', 'lastTransactionDate')"
                                            >
                                                <img
                                                    :src="require('@/assets/images/caret-down.png')"
                                                    alt=""
                                                    class="directional-up"
                                                    style="height: 8px; width: 7px"
                                                />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </th>
                            <th class="pb3 box-border-bottom tc" style="padding-right:10px">Action</th>
                        </tr>
                    </thead>

                    <template v-for="entity in entities?.data" v-bind:key="entity._id">
                        <tr v-if="entity?.company !== 'Clientes Varios' && entity?.company !== 'Unknown'" class="font-w1">
                            <td class="pv3 pr4 box-border-bottom tl">
                                {{ entity._id?.toUpperCase().slice(19, entity._id.length) }}
                            </td>
                            <td
                                v-if="entity?.company === 'Clientes Varios' || entity?.company === 'Unknown'"
                                class="pv3 pr4 box-border-bottom tl flex items-center"
                            >
                                <img
                                    :src="require('@/assets/images/profile-user.png')"
                                    class="pr1 h-20 w-20"
                                    style="width: 30px; height: 30px; margin-right: 10px"
                                    :alt="entity?.fullName || entity?.company"
                                />

                                <span class="font-w2">
                                    <template v-if="entity?.lastName">
                                        {{
                                            `${entity?.firstName?.trim()} ${entity?.lastName?.trim()}` || entity?.company?.trim()
                                        }}
                                    </template>

                                    <template v-else>
                                        {{ `${entity?.firstName?.trim()}` || entity?.company?.trim() }}
                                    </template>
                                </span>
                            </td>
                            <td v-else class="pr3">
                                <router-link
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(14)"
                                    :to="{ name: 'Entity', params: { id: entity._id } }"
                                    class="pv3 pr4 box-border-bottom tl flex items-center pointer"
                                >
                                    <img
                                        :src="require('@/assets/images/profile-user.png')"
                                        class="pr1 h-20 w-20"
                                        style="width: 30px; margin-right: 10px"
                                        :alt="entity?.fullName || entity?.company"
                                    />
                                    <span class="font-w2">
                                        <template v-if="entity?.lastName">
                                            {{
                                                `${entity?.firstName?.trim()} ${entity?.lastName?.trim()}` ||
                                                entity?.company?.trim()
                                            }}
                                        </template>

                                        <template v-else>
                                            {{ `${entity?.firstName?.trim()}` || entity?.company?.trim() }}
                                        </template>
                                    </span>
                                </router-link>
                                <div v-else>
                                    <img
                                        :src="require('@/assets/images/profile-user.png')"
                                        class="pr1 h-20 w-20"
                                        style="width: 30px; margin-right: 10px"
                                        :alt="entity?.fullName || entity?.company"
                                    />
                                    <span class="font-w2x">
                                        <template v-if="entity?.lastName">
                                            {{
                                                `${entity?.firstName?.trim()} ${entity?.lastName?.trim()}` ||
                                                entity?.company?.trim()
                                            }}
                                        </template>

                                        <template v-else>
                                            {{ `${entity?.firstName?.trim()}` || entity?.company?.trim() }}
                                        </template>
                                    </span>
                                </div>
                            </td>
                            <td class="pv3 pr4 box-border-bottom tl">{{ entity?.email || '-' }}</td>
                            <td class="pv3 pr4 box-border-bottom tl">
                                <div class="flex items-center justify-center">
                                    <span class="mr1">{{ entity?.phone || '-' }}</span>
                                    <span v-if="entity?.phone">
                                        <img
                                            src="@/assets/images/phone.svg"
                                            @click="makePhoneCall(entity?.phone)"
                                            style="margin-right: 5px; width: 15px"
                                            alt="phone"
                                        />
                                        <img
                                            src="@/assets/images/whatsapp.svg"
                                            @click="openWhatsApp(entity?.phone)"
                                            style="width: 15px"
                                            alt="whatsApp"
                                        />
                                    </span>
                                </div>
                            </td>

                            <td class="pv3 pr4 box-border-bottom tl">
                                <div
                                    class="flex items-center justify-center br-pill ph2 pv1"
                                    style="background: #0186d3; color: #ffffff"
                                    :style="setBGAndTextColor(entity)"
                                >
                                    <span class="pa0" v-if="entity?.roles && entity?.roles?.length > 1">
                                        {{ `${entity?.roles?.[0]} + ${entity?.roles?.length - 1}` || '-' }}
                                    </span>
                                    <span class="pa0 ttc" v-else>{{ entity?.roles?.[0] || '-' }}</span>
                                </div>
                            </td>
                            <td class="pv3 pr4 box-border-bottom tr" style=" padding-right: 20px">
                                {{ formatAmount(entity?.sales_amount, $store.state.Settings?.currency) }}
                            </td>
                            <td class="pv3 pr4 box-border-bottom tl">{{ formatDate(entity?.lastTransactionDate) || '-' }}</td>
                            <td class="pv3 pr4 box-border-bottom tc">
                                <a
                                    v-if="role === 'admin' || role === 'owner' || rolePermissions.includes(39)"
                                    href=""
                                    @click.prevent="archive(entity)"
                                    title="Archive"
                                    class="tc"
                                >
                                    <img :src="require('@/assets/images/delete.svg')" alt="archive" />
                                </a>
                            </td>
                        </tr>
                    </template>
                </table>

                <TableEmptyState v-if="entities?.data?.lenght <= 0" :data-array="entities?.data" />
            </div>

            <!-- pagination -->
            <Pagination :pages="entities?.pagination" route-name="Entities" :onNavigate="onNavigate" />
            <!-- pagination -->
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, reactive, computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2'
import AppWrapper from '@/layout/AppWrapper'
import Pagination from '@/components/Pagination'
import CreateEntity from '@/components/CreateEntity'
// import AlgoliaAutoComplete from '@/components/AlgoliaAutoComplete'
import EntityBulkUploadModal from '@/views/Entities/widgets/EntityBulkUploadModal'
import { formatDate, formatAmount, formatDateFilter, sortByNumericData, sortAlphaNumericData } from '@/utils/lib'
import Spinner from '@/components/Spinner'
import TableEmptyState from '@/components/TableEmptyState'
import SearchInput from 'vue-search-input'

export default {
    name: 'Entities',
    components: {
        AppWrapper,
        Pagination,
        CreateEntity,
        Spinner,
        TableEmptyState,
        // AlgoliaAutoComplete,
        SearchInput,
        EntityBulkUploadModal,
    },

    data() {
        return {
            term: '',
        }
    },
    data: () => ({
        isOpen: true,
    }),

    setup() {
        const route = useRoute()
        const store = useStore()
        const entities = computed(() => store?.state.Entity.entities)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const orgData = computed(() => store?.state?.Auth?.userData)
        const plan = computed(() => store.state?.Settings?.paymentPlan)
        const getTheValue = ref('')
        const entyValue = ref(null)

        const onNavigate = (query) => {
            store.dispatch('Entity/getEntities', query)
        }

        const toggleCreateEntity = (payload) => {
            store.dispatch('Entity/toggleCreateEntity', payload)
            // store.dispatch('Entity/setEntityName', '')
        }

        watch(
            () => entities.value,
            (prevValue, currValue) => {
                if (prevValue !== currValue) {
                    //
                    // if (prevValue || currValue) {
                    state.entities = prevValue || currValue
                    // }
                    // console.log(state.entities.data, 'new values from entities')
                    //  handleFilter(state.entities.data)
                }
            }
        )

        // const handleClickedEntity = (id) => {
        //     router.push({ name: 'Entity', params: { id } })
        // }

        // Set the background color of the roles on large screens
        const setBGAndTextColor = (entityItem) => {
            const item = entityItem?.roles?.[0]

            switch (item) {
                case 'customer':
                    return 'background-color: #edf0fd; color: #132c8c;'

                case 'vendor':
                    return 'background-color: #ce541b; color: white;'

                case 'debtor':
                    return 'background-color: #14c773; color: white;'

                case 'investor':
                    return 'background-color: #132c8c; color: white;'

                case 'creditor':
                    return 'background-color: #eb2d36; color: white;'

                case 'supplier':
                    return 'background-color: #662deb; color: white;'

                default:
                    return 'background-color: #662deb; color: white;'
            }
        }

        const entityRoles = ['customer', 'vendor', 'debtor', 'investor', 'creditor', 'supplier']

        const refreshHandler = () => {
            state.isEntityRoleOpen = false
            store.dispatch('Entity/getEntities')
        }

        const month = new Date().getMonth()
        const year = new Date().getFullYear()
        const day = new Date().getDate()
        const lastYear = year - 1

        const defaultStartDate_1 = new Date(year, 0, 1)

        const state = reactive({
            loading: true,
            startDate1: defaultStartDate_1,
            entities: entities.value,
            isEntityRoleOpen: false,
            filterEntityBy: null,
            searchVal: '',
            isOpen: true,
        })
        // console.log(state.startDate1)

        // const handleEntity = () => {
        //     const query = formatDateFilter(state.startDate1)
        //     console.log(query)
        //     store.dispatch('Entity/getEntities', query)
        // }
        const handleEntity = () => {
            // const query = formatDateFilter(state.startDate1)
            // console.log(query)
            // store.dispatch('Entity/getEntities', query)
            // console.log(entities?.data, 'entities data')
        }

        const toggleEntityRoleFilter = () => {
            state.isEntityRoleOpen = !state.isEntityRoleOpen
        }

        const showEntityRoleFilter = () => {
            state.isEntityRoleOpen = true
        }

        const hideEntityRoleFilter = () => {
            state.isEntityRoleOpen = false
        }

        const filterEntity = (option) => {
            state.filterEntityBy = option
            hideEntityRoleFilter()

            const query =
                route.query.limit && route.query.page
                    ? { filter_by: option, limit: route.query.limit, page: route.query.page }
                    : { filter_by: option }
            state.startDate1 = formatDateFilter(state.startDate1)
            // console.log(state.startDate1)
            store.dispatch('Entity/getEntities', query)
        }

        const clickModalEntities = ref(false)
        const closeModal = () => {
            clickModalEntities.value = false
        }

        const sortTransactionsTableOld = (order, value) => {
            getTheValue.value = value
            const query = {
                data: entities.value.data,
                order: order,
                value: value,
            }
            if (value === 'phone') sortByNumericData(query)
            else {
                sortAlphaNumericData(query)
            }
        }

        const exportEntities = () => {
            if (plan.value?.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `Exporting of data is only available for Paid plans`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442'
                }).then(async result => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                })
                return
            }

            store.dispatch('Entity/exportEntities', {}).then(response => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = `${orgData.value?.org || ''}-entities-export.csv`
                fileLink.setAttribute('download', fileName)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
                // state.downloading = false
            })
        }

        const sortTransactionsTable = (mode, sortBy) => {
            state.isOpen = !state.isOpen
            getTheValue.value = sortBy
            const query = {
                filterType: route.query.filterType || 'customrange',
                limit: route.query.limit || 25,
                page: route.query.page || 1,
                sort: route.query.sort || sortBy,
                mode: route.query.mode || mode,
            }
            store.dispatch('Entity/getEntities', query)
        }

        const archive = (entity) => {
            Swal.fire({
                title: `Are you sure you want to archive this customer - ${entity.firstName}?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, archive it!',
                denyButtonText: `No, cancel!`,
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('Entity/archiveEntity', entity._id)
                }
            })
        }

        const search = () => {
            const query = {
                limit: route.query.limit || 25,
                page: route.query.page || 1,
                filterType: 'search',
                q: state.searchVal,
            }

            state.startDate1 = formatDateFilter(state.startDate1)
            store.dispatch('Entity/getEntities', query)
        }

        const toggleEntityBulkImportModal = (status) => {
            store.dispatch('Entity/toggleEntityBulkImportModal', status)
        }

        const makePhoneCall = (phone) => {
            window.open(`tel:${phone}`)
        }
        const openWhatsApp = (phone) => {
            const phoneNumber = phone.replace(/\D/g, '').slice(-10)
            const url = `https://wa.me/+234${phoneNumber}`
            window.open(url, '_blank')
        }

        const getEntities = () => {
            const query = route.query.limit && route.query.page ? { limit: route.query.limit, page: route.query.page } : null
            state.startDate1 = formatDateFilter(state.startDate1)

            const params = new URLSearchParams(window.location.search)
            let type = params.get('group') || 'Customers'
            if (type === 'customers') {
                filterEntity(entityRoles[0])
            }
            if (type === 'suppliers') {
                filterEntity(entityRoles[5])
            }
            entyValue.value = type.charAt(0).toUpperCase() + type.slice(1)
            store.dispatch('Entity/getEntities', query)
        }

        // filter entities
        onMounted(() => {
            window.addEventListener('click', (event) => {
                const modalIcon = document.querySelector('.clickModalIcon')

                if (
                    !(event.target.closest('.clickModalIcon') || event.target === modalIcon) &&
                    clickModalEntities.value === true
                ) {
                    closeModal()
                }
            })

            getEntities();
            store.dispatch('Settings/getPlan')
        })

        return {
            entities,
            setBGAndTextColor,
            toggleCreateEntity,
            formatDate,
            formatAmount,
            onNavigate,
            refreshHandler,
            state,
            handleEntity,
            entityRoles,
            toggleEntityRoleFilter,
            showEntityRoleFilter,
            hideEntityRoleFilter,
            filterEntity,
            sortTransactionsTable,
            archive,
            rolePermissions,
            role,
            orgData,
            getTheValue,
            search,
            toggleEntityBulkImportModal,
            clickModalEntities,
            closeModal,
            getTheValue,
            entyValue,
            makePhoneCall,
            openWhatsApp,
            exportEntities,
        }
    },
}
</script>

<style scoped>
/* Entity filter dropdown */
.entity-filter-dropdown {
    cursor: pointer;
    position: relative;
    /* left: 50%; */
    /* top: 50%; */
    /* transform: translate(-50%, -70px); */
    /* min-width: 350px; */
    /* height: 40px; */
    /* z-index: 1; */
}

/* Selected */
.entity-filter-btn {
    position: relative;
    /* z-index: 2; */
    /* display: block; */
    width: 100%;
    height: 30px;
    padding: 0 10px;
    /* background: #054641; */
    /* border-radius: 10px; */
    font: 1.25rem/40px 'Ubuntu', Helvetica, Arial, sans-serif;
    /* text-shadow: 2px 2px 0px #000; */
    /* color: #fff;   */
}

/* Arrow */
.entity-filter-btn:after {
    opacity: 0.5;
    display: inline-block;
    margin-left: 10px;
    content: '▼';
}

/* Hover state */
.entity-filter-btn:hover:after {
    opacity: 1;
}

/* Options wrapper (toggled by isOpen) */
.options {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1;
    width: 100%;
    margin-top: 3px;
    background: #fff;
    /* border-radius: 10px; */
    border: 1px solid rgba(0, 0, 0, 0.25);
    text-align: left !important;
}

/* Option */
.option {
    padding: 5px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

/* Hover state */
.option:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* Reset last child for a nice layout */
.option:last-child {
    border-bottom: none;
}

/* Transition */
.fade-enter-active,
.fade-leave-active {
    transition: all 0.25s ease-out;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
    transform: translateY(-30px);
}
</style>
